<template>
  <CModal :show.sync="Toggle" :centered="true" size="lg" :closeOnBackdrop="!Submit" @update:show="HandleHideModal">
    <CAlert color="secondary">
      <CIcon name="cil-bell" /> 請將「<span class="font-weight-bold">qs-sheet-reader@ksong-eb5c0.iam.gserviceaccount.com</span>」此帳號以編輯者身份加入至使用者共用清單內，即可將網址貼入以下欄位並匯出至系統中。
    </CAlert>
    <CAlert color="warning">
      <CIcon name="cil-warning" /> 因篩選大範圍區間匯出可能會導致Google Sheets API會有匯出延遲的問題，建議使用Google Sheets匯出的筆數在2000筆以內。
    </CAlert>
    <CInput :label="'試算表網址'" v-model="SheetURL" placeholder="網址格式為: https://docs.google.com/spreadsheets/d/<DocID>/edit#gid=<SheetID>"/>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Global.ExportToGoogleSheet') }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton @click="Export()" color="success" :disabled="SheetURL === '' || Loading">
          {{ $t('Global.Execute') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>
<script>
export default {
  props: ['ChooseUsers', 'Loading', 'Submit', 'Toggle'],
  data() {
    return {
      SheetURL: ''
    }
  },
  methods: {
    HandleHideModal() {
      this.$parent.ToggleModal = false
      this.$emit('update:Toggle', false)
    },
    Export() {
      if (!this.SheetURL) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + this.$t('Message.Global/WrongSheetURL'),
          type: 'error'
        })
      }
      this.$emit('ExecuteExport', {
        SheetURL: this.SheetURL
      })
    },
    // todo: deprecation
    Export2() {
      this.Loading = true
      this.$Progress.start()
      let url
      const PostData = {
        SheetURL: this.SheetURL
      }
      switch(this.$router.currentRoute.name) {
        case 'products-list':
          url = '/product/export/googleSheet'
          break
        case 'post-list':
          url = '/content/export/googleSheet'
          break
        case 'organization-member-list':
          url = '/user/export/googleSheet'
          if (this.ChooseUsers.length > 0) {
            PostData.Uids = this.ChooseUsers
          }
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            type: 'error'
          })
          return
      }

      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url,
        method: 'post',
        data: PostData
      }).then(() => {
        this.Loading = false
        this.HandleHideModal()
        this.$Progress.finish()
        this.$emit('Success')
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/ExportSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ExportFail'),
          type: 'error'
        })
      })
    }
  }
}
</script>
